@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Anton&family=Inconsolata:wght@900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
@font-face {
  font-family: "impact";
  src: local("impact"), url(./assets/fonts/impact.ttf) format("ttf");
}
html {
  scroll-behavior: smooth;
}
